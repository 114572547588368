import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Title from "../components/title";
import Image from "../components/image";
export const _frontmatter = {
  "title": "About Us",
  "calltoaction": "Join us today",
  "description": "Our Mission And Vision",
  "tags": ["mission", "about", "vision"],
  "path": "/about",
  "date": "2019-11-07T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Layout mdxType="Layout">
  <SEO title={props.pageContext.frontmatter.title} mdxType="SEO" />
  <Title title="What We Stand For" filename="luis-melendez-Pd4lRfKo16U-unsplash.jpg" calltoaction={props.pageContext.frontmatter.description} mdxType="Title" />
  <div className="flex-container-right">
      <div className="half">   
          <Image filename="seniors.jpg" mdxType="Image" />
          <div className="overlay" style={{
            backgroundColor: `orange`
          }}></div>
          <h2>Our Mission</h2>
      </div>
      <div className="half">  
          <p>Our mission is to provide excellent, reliable, experienced, & dedicated support personnel consisting of Registered Nurses, Licensed Vocational Nurses, Certified Nursing Assistants, Physical and Occupational Therapists, PT and OT Assistants, Speech Language Pathologists and SLP Assistants in different healthcare and private settings.</p>
      </div> 
  </div>
  <div className="flex-container">
      <div className="half">    
          <Image filename="post-its.jpg" mdxType="Image" />
          <div className="overlay" style={{
            backgroundColor: `orange`
          }}></div>
          <h2>Our Vision</h2>
      </div>
      <div className="half">    
          <p>The Company’s vision is to be the best and most convenient one-stop shop registry for healthcare services, especially when there’s an emergency need that arises for our clients, may it be an abrupt need of personnel, a fill-in staff of a regular shift, or a long-term supply in staffing.</p>
      </div>
  </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      